import country from "country-list-js";

export const getCountryCode = (countryName = "Canada") => {
  const name_list = country.names();
  let lower_name = countryName.indexOf("&") > 0 ? countryName.split("&")[0] : countryName;
  lower_name = lower_name.toLowerCase().split(" ").join("");
  if(lower_name.indexOf("&")>0)lower_name = lower_name.split("&")[0];
  let code;
  for(var i = 0; i< name_list.length; i++) {
    const lower_list = name_list[i].toString().toLowerCase().split(" ").join("");
    if(lower_name === lower_list){
      const country_item = country.findByName(name_list[i]);
      code = country_item.code.iso2;
    }
  };
  if(countryName === "USA")code="US";
  return code ?? "EU";
}