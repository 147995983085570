import React, { useEffect, useState } from "react";
import axios from "axios";
import SimItem from "../components/SimItem";
import { useSelector } from 'react-redux';
import { eSimActions } from "../store";
import { useDispatch } from "react-redux";
const MySims = () => {
  const dispatch = useDispatch();
  const auth = useSelector((x) => x.auth.value);
  const email = useState(auth?.email);
  const baseUrl = process.env.REACT_APP_API_URL;
  const [simArr, setSimArr] = useState([]);
  useEffect(()=>{
    dispatch(eSimActions.deleteOrder());
    getSims();
  }, [])
  const getSims = async () => {
    try{
      const res = await axios.post(`${baseUrl}/api/auth/get_sims`,{
        email: email
      });
      setSimArr(res.data.data);
    }catch(err){
      
    }
  }
  return (
    <div className="w-full h-screen px-3 mt-4">
      <h3 className="mt-10 mb-3 text-center">
        Would you like to buy a new eSIM?
      </h3>
      {simArr.length > 0 && simArr.map((item, index) => (
        <SimItem {...item} key={index} />
      ))}
    </div>
  );
};

export default MySims;
