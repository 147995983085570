import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import QRCode from "qrcode";
import { useEffect, useState } from "react";

const QrInfoItem = (props) => {
  const [qrCodeUrl, setQrCodeUrl] = useState("");;
  const [viewPanel , setViewPanel] = useState(false);
  const [viewText, setViewText] = useState("View Text");
  const [qrContents, setQrContents] = useState("");
  const changeText = () => {
    if(viewText === "View Text") {
      setViewText(qrContents);
    }else{
      setViewText("View Text");
    }
  }
  const copyObject = async () => {
    try {
      const response = await fetch(qrCodeUrl); // URL to your image
      const blob = await response.blob();
      const clipboardItem = new ClipboardItem({'image/png': blob});
      await navigator.clipboard.write([clipboardItem]);
      setViewPanel(false);
    } catch (error) {
        console.error('Error copying image to clipboard: ', error);
    }
  }
  const download = () => {
    const link = document.createElement("a");
    link.download = "qr_code.png";
    link.href = qrCodeUrl;
    link.click();
    setViewPanel(false);
  }
  const copyClipboard = async () => {
    try {
      await navigator.clipboard.writeText(qrContents);
      setViewPanel(false);
  } catch (error) {
      console.error('Error copying image to clipboard: ', error);
  }
  }
  useEffect(() => {
    if (props.activationCode) {
      QRCode.toDataURL("LPA:1$smdp.io$" + props.activationCode)
        .then((url) => {
          setQrCodeUrl(url);
          setQrContents("LPA:1$smdp.io$" + props.activationCode);
        })
        .catch((error) => {
          console.error("Failed to generate QR code", error);
        });
    }
  }, [props.activationCode]);

  return (
    <Container>
      <Row>
        <Col sm={4} className="relative">
        {qrCodeUrl && <img onClick={()=>setViewPanel(!viewPanel)} src={qrCodeUrl} alt="QR Code" className="w-full transition-transform duration-300 transform cursor-pointer hover:scale-200" />}
        {viewPanel && 
          <div className="absolute z-10 top-[60px] right-[-230px] shadow-[1px_1px_20px_-10px_black] flex flex-col items-center justify-center text-white bg-gray-800">
            <div className="w-64 p-4 text-left bg-gray-700 rounded-lg shadow-lg">
              <ul className="space-y-2">
                <li className="p-2 rounded cursor-pointer hover:bg-gray-600">Share...</li>
                <li className="p-2 rounded cursor-pointer hover:bg-gray-600" onClick={download}>Save to Photos</li>
                <li className="p-2 rounded cursor-pointer hover:bg-gray-600" onClick={copyClipboard}>Copy</li>
                <li className="p-2 rounded cursor-pointer hover:bg-gray-600" onClick={copyObject}>Copy Image</li>
                <li className="p-2 rounded cursor-pointer hover:bg-gray-600" onClick={changeText}>{viewText}</li>
                <li className="p-2 rounded cursor-pointer hover:bg-gray-600">Add eSIM</li>
              </ul>
            </div>
          </div>
        }
        </Col>
        
        <Col sm={8}>
          <p className="text-[18px] pl-5">
            Scan this information and enter details manually to install eSim.
            *Make sure your device has a stable internet connection before
            installing.
          </p>
        </Col>
      </Row>
    </Container>
  );
};
export default QrInfoItem;
