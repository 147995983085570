
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
const ActivationStep = (props) => {
    return (
        <Container>
            {props.data.length > 0 && props.data.map((step, index) => {
                return <Row key={index} className="text-center">
                    <Col><h5>{step.startDate}</h5></Col>
                    <Col><h5><b>
                        <font color={step.status === "Active" ? "lightgreen" : "grey"}>
                            {step.status === "Active" ? "ACTIVE" : "INACTIVE"}
                        </font>
                        </b></h5>
                    </Col>
                </Row>
            })}
        </Container>
    )
}

export default ActivationStep;