import Button from "react-bootstrap/Button";
import InsideItem from "../components/InsideItem";
import CustomAccordion from "../components/CustomAccordion";
import InsidePlanItem from "../components/InsidePlanItem";
import QrInfoItem from "../components/QrInfoItem";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getCountryCode } from "../util/countryUtil";
import ActivationStep from "../components/ActivationStep";
import { useDispatch } from "react-redux";
import { eSimActions } from "../store";
import OtherPlan from "../components/OtherPlan";
const Inside = () => {
  const baseUrl = process.env.REACT_APP_API_URL;

  const { account_id } = useParams();
  const dispatch = useDispatch();

  const auth = useSelector((x) => x.auth.value);
  const email = useState(auth?.email); 

  const [loading, setLoading] = useState(true);
  const [countryName, setCountryName] = useState("");
  const [countryCode, setCountryCode ] = useState("US");
  const [order, setOrder] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [activationCode, setActivationCode] = useState("");
  const [activeSteps, setActiveSteps] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [template, setTemplate] = useState(null);
  const [plans, setPlans] = useState([]);
  useEffect(() => {
    getSims();
  }, []);
  const goTopup = () => {
    dispatch(eSimActions.setIccid(account_id));
    window.location.href=`${window.location.origin}`;
  }
  const getSims = async () => {
    setLoading(true);
    try {
      const res_sim = await axios.post(`${baseUrl}/api/auth/get_sims`, { email: email });
      const sim_data = res_sim.data.data;
      sim_data.map((sim_item) => {
        if(sim_item.iccid === account_id){
          setLocationId(sim_item.location_id);
          setOrder(sim_item.order);
          setCountryName(sim_item.country_name);
          setCountryCode(getCountryCode(sim_item.country_name));
        }
      });
      const res = await axios.post(`${baseUrl}/api/esim/get_sim_by_imsi`,{ iccid : account_id });
      setOrder(sim_data.order);
      setPhoneNumber(res.data.phone_number);
      setActivationCode(res.data.activation_code);
      setActiveSteps(res.data.active_step);
      const package_iccid = await axios.post(`${baseUrl}/api/esim/get_package_iccid`,
        {
        listSubscriberPrepaidPackages: {
          iccid: account_id,
        }
      });
      setTemplate(package_iccid.data.listSubscriberPrepaidPackages.packages[0]);
      let other_plans = package_iccid.data.listSubscriberPrepaidPackages.packages;
      other_plans.shift();
      setPlans(other_plans);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };
  
  const cardData = {
    title: countryName,
    orderID: order,
    phone: phoneNumber,
    coverage: countryName,
    countryCode: countryCode,
    status: true,
  };

  const accordions = [
    {
      title: "Current Plan",
      content: (
        <InsidePlanItem
          activeTime={template ? template.tsactivationutc : ""}
          endTime={template ? template.tsexpirationutc : ""}
          status={template ? template.active : false}
          storage={template ? template.pckdatabyte : 0}
          usedStorage = {template ? template.useddatabyte : 0}
          voice={template ? template.pckmocsecond : 0}
          usedVoice = {template ? template.usedmocsecond : 0}
        />
      ),
    },
    {
      title: "Show QR & Manual INFO",
      content: <QrInfoItem activationCode = {activationCode} />,
    },
    {
      title: "Active Steps",
      content: <ActivationStep data={activeSteps} />,
    },
    {
      title: "Replace eSIM",
      content: <h3>Replace eSIM Contents coming soon</h3>
    },
    {
      title: "Other Plans",
      content: (<OtherPlan data={plans} />),
    },
  ];

  return (
    <div className="w-full px-3 pt-4">
      {loading ? 
        <img
          src="/images/Spinner.png"
          alt=""
          className="w-[20%] sm:w-[100px] animate-spin  m-auto"
        /> : <InsideItem {...cardData} />}
      <Button
        size="lg"
        className="w-full border-white"
        style={{ backgroundColor: "#00274C" }}
        onClick={goTopup}
      >
        TOP UP +
      </Button>
      <CustomAccordion data={accordions} />
    </div>
  );
};

export default Inside;

