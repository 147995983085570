import Flags from "country-flag-icons/react/1x1";

const InsideItem = (props) => {
  const { orderID, phone, coverage, title, countryCode, status } = props;
  return (
    <div className="flex w-full items-center shadow-[1px_2px_5px_grey] p-2 rounded-lg mb-3">
      <div className="w-[20%] flex justify-center">
        <div className="w-[50px] h-[50px] rounded-full overflow-hidden border-2 border-white shadow-[0px_3px_5px_1px_rgba(0,0,0,0.3)]">
          {countryCode && title !== "GLOBAL" ? (
            <div className="w-full h-full">
              {Flags[countryCode]({ title: countryCode })}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="flex flex-col items-start justify-between w-full px-2">
        <h3 className="m-0">{title}</h3>
        <div className="flex items-center justify-between w-full">
          <div className="flex flex-col items-start justify-between">
            <p className="m-0">order#</p>
            <p className="m-0">{orderID}</p>
          </div>
          <div className="flex flex-col items-end justify-between">
            <p className="m-0">Status</p>
            <h6 className="m-0">
              {status ? (
                <font color="lightgreen">ACTIVE</font>
              ) : (
                <font color="grey">INACTIVE</font>
              )}
            </h6>
          </div>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="flex flex-col items-start justify-between">
            <p className="m-0">Coverage</p>
            <p className="m-0">{coverage}</p>
          </div>
          <div className="flex flex-col items-end justify-between">
            <p className="m-0">Phone #</p>
            <p className="m-0">{phone}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InsideItem;
