import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { authActions } from "../store";
import { useSelector } from "react-redux";
import { getCountryCode } from "../util/countryUtil";
import Flags from "country-flag-icons/react/1x1";
import { history } from '../helpers/history';
import { alertActions } from "../store";
import { userActions } from "../store";

const Login = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState([]);
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, formState, setValue, handleSubmit } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const dataSize = useSelector((x) => x.eSim.dataSize);
  const minute = useSelector((x) => x.eSim.minute);
  const cost = useSelector((x) => x.eSim.cost);
  const countryName = useSelector((x) => x.eSim.countryName);
  const period = useSelector((x) => x.eSim.period);
  const [countryCode , setCountryCode] = useState(null);
  const [login, setLogin] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  function onLoginSubmit({ email, password }) {
    return dispatch(authActions.login({ email, password }));
  }

  async function onSubmit(data) {
    dispatch(alertActions.clear());
    try {
      await dispatch(userActions.register(data)).unwrap();

      // redirect to login page and display success alert
      history.navigate("/login");
      dispatch(
        alertActions.success({
          message: "Registration successful",
          showAfterRedirect: true,
        })
      );
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  }

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if(history.location && history.location.state && typeof history.location.state.from !== 'undefined'){
      const history_item = history.location.state.from;
      if(history_item.pathname==="/checkout")localStorage.setItem("path", JSON.stringify(history_item));
    }
    if(countryName && countryName !== "")
    {
      const code = getCountryCode(countryName);
      setCountryCode(code);
    }
  }, []);

  useEffect(() => {
    if (user.length !== 0) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setValue('email', res.data.email);
          console.log(res.data);
          // setProfile(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  return (
    <div className="w-full h-full sm:w-[60%] m-auto">
      <div className="w-full px-10 mt-24">
        <div className="mt-4 mb-4 text-center form-header">
          <button onClick={() => setLogin(true)} className={`px-4 py-2 m-2 font-semibold text-gray-700 ${login ? "bg-white" : "bg-gray-200"}  rounded-lg hover:bg-gray-200 `}>Login</button>
          <button onClick={() => setLogin(false)} className={`px-4 py-2 m-2 font-semibold text-gray-700 ${!login ? "bg-white" : "bg-gray-200"} rounded-lg hover:bg-gray-300 `}>Signup</button>
          <h6>Enter your info to sign {login ? "in" : "up"} for this app</h6>
        </div>
        {login ? (
           <form onSubmit={handleSubmit(onLoginSubmit)}>
           <Form.Control
             size="lg"
             className={`mb-2 ${errors.email ? "is-invalid" : ""}`}
             type="email"
             id="email"
             name="email"
             {...register("email")}
             placeholder="example@gmail.com"
           />
           <div className="invalid-feedback">{errors.email?.message}</div>
           <Form.Control
             size="lg"
             type="password"
             id="password"
             name="password"
             className={`${errors.password ? "is-invalid" : ""}`}
             {...register("password")}
             placeholder="Enter Password"
           />
           <div className="invalid-feedback">{errors.password?.message}</div>
           <Link to={"/forgot"}>
            <div className="w-full text-lg text-center text-blue-700 cursor-pointer">Forgot Password?</div>
            
          </Link>
           <Button
             className="w-full mt-3 bg-black hover:bg-gray-600"
             size="lg"
             type="submit"
             id="btn_login"
             disabled={isSubmitting}
           >
             {isSubmitting && (
               <span className="spinner-border spinner-border-sm me-1"></span>
             )}
             Sign in
           </Button>
         </form>
        ):(
          <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Control
            size="lg"
            className={`mb-2 ${errors.firstName ? "is-invalid" : ""}`}
            id="username"
            type="text"
            {...register("username")}
            placeholder="Toni Aala"
          />
          <Form.Control
            size="lg"
            className={`mb-2 ${errors.firstName ? "is-invalid" : ""}`}
            type="email"
            id="email"
            {...register("email")}
            placeholder="example@gmail.com"
          />
          <div className="invalid-feedback">{errors.email?.message}</div>
          <Form.Control
            size="lg"
            id="password"
            type={showPassword ? "text" : "password"}
            {...register("password")}
            className={`${errors.firstName ? "is-invalid" : ""}`}
            placeholder="Enter Password"
          />
          <div className="invalid-feedback">{errors.password?.message}</div>
          <div className="flex items-center justify-center">
            <input
              id="check"
              size="lg"
              type="checkbox"
              onClick={() => {
                setShowPassword((showPassword) => !showPassword);
              }}
            />
            <label htmlFor="check" className="ml-1 cursor-pointer">
              Password Show
            </label>
          </div>

          <Button
            className="w-full mt-3 bg-black"
            size="lg"
            type="submit"
            id="btn_submit"
            disabled={isSubmitting}
          >
            {isSubmitting && (
              <span className="spinner-border spinner-border-sm me-1"></span>
            )}
            Sign up
          </Button>
        </form>
        )}
       
        <div className="flex items-center my-2">
          <div className="h-[1px] flex-1 bg-gray-300"></div>
          <span className="m-3 text-gray-400">or continue with</span>
          <div className="h-[1px] flex-1 bg-gray-300"></div>
        </div>
        <Button
          variant="outline-light"
          className="w-full text-black"
          style={{ backgroundColor: "#dddcdc" }}
          size="lg"
          onClick={googleLogin}
        >
          <div className="flex items-center justify-center">
            <img src="/images/google.png" alt="" />
            <p className="mb-0">Google</p>
          </div>
        </Button>
        <p className="mt-3 text-center text-gray-600">
          By clicking continue, you agree to our <b>Terms of service</b> <br />
          and <b>Privacy Policy</b>
        </p>
      </div>
      {false &&  (
        <div className="px-5 py-3" style={{ backgroundColor: "#00274C" }}>
          <div className="flex items-center justify-between">
            <div className="flex flex-col items-start justify-between text-white ">
              <h4>{dataSize} GB</h4>
              <h6>{minute} MINUTES</h6>
            </div>
            <div className="flex flex-col items-start justify-between text-white">
              <p>TOTAL</p>
              <h4>€ {cost} EUR</h4>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex items-center justify-between text-white ">
              <span className="mr-5">eSIM</span>
              <div className="w-[40px] h-[40px] rounded-full overflow-hidden border-2 border-white shadow-[0px_3px_5px_1px_rgba(0,0,0,0.3)]">
                {countryCode ? (
                  <div className="w-full h-full">
                    {Flags[countryCode]({ title: countryName })}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="flex flex-col items-start justify-between ml-3 text-white">
              <h6>{countryName}</h6>
              <span>VALID FOR {period} DAYS</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
