
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Flags from "country-flag-icons/react/1x1";
import { eSimActions } from "../store";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import { getCountryCode } from "../util/countryUtil";
const Payment = () => {
    const dispatch = useDispatch();
    const [processing, setProcessing] = useState(false);
    const auth = useSelector((x) => x.auth.value);
    const email = useState(auth?.email);
    const iccid = useSelector((x) => x.eSim.iccid);
    const dataSize = useSelector((x) => x.eSim.dataSize);
    const minute = useSelector((x) => x.eSim.minute);
    const cost = useSelector((x) => x.eSim.cost);
    const countryName = useSelector((x) => x.eSim.countryName);
    const [countryCode, setCountryCode] = useState("");
    const period = useSelector((x) => x.eSim.period);
    const packageId = useSelector((x) => x.eSim.packageId);
    const [orderDate, setOrderDate] = useState("");
    const baseUrl = process.env.REACT_APP_API_URL;
    
    useEffect(() => {
        if(!packageId || packageId === '')window.location.href=`${window.location.origin}`;
        
        setCountryCode(getCountryCode(countryName));
        addNewOrder();
    }, []);
    const addNewOrder = async (data) => {
        try{
            const order_date = new Date().toISOString().split(".")[0];
            setOrderDate(order_date);
            const req_body = {
                package_id: packageId,
                order_date: order_date,
                email: email,
                iccid : iccid,
                paid : true,
                country_name : countryName,
                data_size : dataSize,
                period : period,
            }
            await axios.post(`${baseUrl}/api/auth/add_new_order`, req_body);
        }catch(e){
            console.log(e);
        }
    }
    const getSimAffectPackage = async () => {
        setProcessing(true);
        await getImsiAffectPackage();
        setProcessing(false);
    }
    
    const getImsiAffectPackage = async () => {
        try {
            const res = await axios.post(
                `${baseUrl}/api/esim/get_imsi_affect_package`,
                {
                    packageTemplateId: packageId,
                    validateDate: period,
                    email: email,
                    iccid : iccid
                }
            );
            if(!iccid){
                const affect_body = {
                    email : email,
                    package_id : packageId,
                    account_id : res.data.affectPackageToSubscriber.accountId,
                    iccid : res.data.affectPackageToSubscriber.iccid,
                    order : res.data.affectPackageToSubscriber.userSimName,
                    paid : true,
                    title : countryName,
                    country_name : countryName
                };
                await axios.post(`${baseUrl}/api/auth/newOrder`, affect_body);
            }
            dispatch(eSimActions.deleteOrder());
            const req_body = {
                package_id: packageId,
                order_date: orderDate,
                email: email,
            }
            const remove_order_res = await axios.post(`${baseUrl}/api/auth/remove_order`, req_body);
            window.location.href = `${window.location.origin}/mysims`;
        } catch (err) {
            
        }
    };

    return (
        <div className="w-full h-full">
            <div className="w-full px-10 mt-24 text-center">
                <div className="mt-4 mb-4 form-header">
                    <h4 className="text-gray-700 hover:text-black ">
                        Payment was successful
                    </h4>
                {iccid ? <h6>Do you want to charge now</h6> : <h6>Do you want to active now?</h6>}
                </div>
                <div className="w-full h-44">
                    <Button
                        className="bg-[#00274C] w-2lg hover:bg-gray-600"
                        size="lg"
                        onClick={getSimAffectPackage}
                    >
                        { processing && (
                            <span className="spinner-border spinner-border-sm me-1"></span>
                        )}
                        {iccid ? <>Affect Now</> : <>Active Now</>}
                    </Button>
                </div>
            </div>
            <div className="px-5 py-3" style={{ backgroundColor: "#00274C" }}>
                <div className="flex items-center justify-between">
                <div className="flex flex-col items-start justify-between text-white ">
                    <h4>{dataSize}GB</h4>
                    <h6>{minute} MINUTES</h6>
                </div>
                <div className="flex flex-col items-start justify-between text-white">
                    <p>TOTAL</p>
                    <h4>€ {cost} EUR</h4>
                </div>
                </div>
                <div className="flex items-center">
                <div className="flex items-center justify-between text-white ">
                    <span className="mr-5">eSIM</span>
                    <div className="w-[40px] h-[40px] rounded-full overflow-hidden border-2 border-white shadow-[0px_3px_5px_1px_rgba(0,0,0,0.3)]">
                    {countryCode ? (
                        <div className="w-full h-full">
                        {Flags[countryCode]({ title: countryName })}
                        </div>
                    ) : (
                        ""
                    )}
                    </div>
                </div>
                <div className="flex flex-col items-start justify-between text-white">
                    <h6>{countryName}</h6>
                    <p>VALID FOR {period} DAYS</p>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Payment;