import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { store } from './store';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <GoogleOAuthProvider clientId='435219984939-arrdre0cnmcp4ohq9vqba8ej7cbm7enl.apps.googleusercontent.com'>
        {/* <React.StrictMode> */}
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        {/* </React.StrictMode> */}
    </GoogleOAuthProvider >
);
reportWebVitals();
