import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const Footer = () => {
  const [isManager , setIsManager] = useState(false);
  const auth = useSelector((x) => x.auth.value);
  const email = useState(auth?.email);
  useEffect(() => {
    if((email && email[0] === "afliscano3@gmail.com") || (email && email === "afliscano3@gmail.com")){
      setIsManager(true);
    }
  }, []);
  return (
    <footer className="fixed bottom-0 left-0 right-0 z-20 w-full bg-white">
      <div className="flex justify-between w-full px-10 sm:w-[60%] mx-auto max-w-[1600px]">
        <Link to={"/"}>
          <button className="bg-transparent">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="#00274C"
              className="size-10"
            >
              <path d="M8.543 2.232a.75.75 0 0 0-1.085 0l-5.25 5.5A.75.75 0 0 0 2.75 9H4v4a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 1 1 2 0v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V9h1.25a.75.75 0 0 0 .543-1.268l-5.25-5.5Z" />
            </svg>
          </button>
        </Link>
        <Link to={"/"}>
          <button className="bg-transparent">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="#00274C"
              className="size-10"
            >
              <path
                fillRule="evenodd"
                d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </Link>
        <Link to={"/mysims"}>
          <button className="bg-transparent">
            <img src="/images/esim.png" className="size-10" alt=""/>
          </button>
        </Link>
        {isManager && 
          <Link to={"/usermanage"}>
            <button className="bg-transparent">
              <img src="/images/users.png" className="size-10" alt=""/>
            </button>
          </Link>
        }
        <Link to={"/account"}>
          <button className="bg-transparent">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="#00274C"
              className="size-10"
            >
              <path
                fillRule="evenodd"
                d="M2 3.75A.75.75 0 0 1 2.75 3h10.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 3.75ZM2 8a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 8Zm0 4.25a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          </Link>
      </div>
    </footer>
  );
};

export default Footer;
