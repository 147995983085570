import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { getCountryCode } from "../util/countryUtil";
import Flags from "country-flag-icons/react/1x1";
import { eSimActions } from "../store";
import { useDispatch } from "react-redux";

const Checkout = () => {
  const dispatch = useDispatch();
  const [stripePromise, setStripePromise] = useState("");
  const auth = useSelector((x) => x.auth.value);
  const dataSize = useSelector((x) => x.eSim.dataSize);
  const minute = useSelector((x) => x.eSim.minute);
  const cost = useSelector((x) => x.eSim.cost);
  const countryName = useSelector((x) => x.eSim.countryName);
  const period = useSelector((x) => x.eSim.period);
  const packageId = useSelector((x) => x.eSim.packageId);
  const email = useState(auth?.email);
  const iccid = useSelector((x) => x.eSim.iccid);
  const [username, setUsername] = useState(auth?.username);
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState(""); 
  const [countryCode, setCountryCode] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [message, setMessage] = useState(" Ready To Pay")
  const [exist, setExist] = useState(false);
  const [orderDate, setOrderDate] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const baseUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    if(!cost || cost === 0 || cost === ''){
      window.location.href=`${window.location.origin}`;
      return;
    }
    const code = getCountryCode(countryName);
    setCountryCode(code);
    getAccount();
    getPublicKey();
  }, []);
  
  const getPublicKey = async () => {
    try {
      axios.get(`${baseUrl}/api/config/publicKey`).then(async (res) => {
        const publicKey = await res.data.publicKey;
        setStripePromise(loadStripe(publicKey));
      });
    } catch (err) {
      window.location.href=`${window.location.origin}`;
    }
  };

  const cardStyle = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
        border: 'solid 2px green'
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
  const getAccount = async () => {
    try {
      const res = await axios.post(`${baseUrl}/api/auth/getAccount`, {
        email,
      });
      if(res.data.address && res.data.address !== "")setExist(true);
      setAddress(res.data.address);
      setCity(res.data.city);
      setCountry(res.data.country);
      setPostalCode(res.data.postcode);
    } catch (err) {
      setExist(false);
    }
  };
  
  const handlePostalCodeChange = (event) => {
    const inputPostalCode = event.target.value;
    const isValidPostalCode = /^[0-9]{5}(?:-[0-9]{4})?$/.test(inputPostalCode);
    setIsValid(isValidPostalCode);
    setPostalCode(inputPostalCode);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!stripe || !elements) {
      setMessage("Stripe Error");
      return;
    }
    try {
      if(!exist)await axios.post(`${baseUrl}/api/auth/setAccount`, {
        email : email,
        country : country,
        address : address,
        city : city,
        postcode : postalCode
      });
      const cardElement = elements.getElement(CardElement);
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      const response = await axios.post(`${baseUrl}/api/payment/createPaymentIntent`,
        { packageId: packageId },
      );
      const { clientSecret } = await response.data;

      const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod.id,
      });

      if (confirmError) {
        setMessage("Payment confirm error.")
        return;
      }

      if (paymentIntent.status !== 'succeeded') {
        setMessage("Payment Failed.");
        return;
      }
      setOrderDate(new Date().toISOString().split(".")[0]);
      const req_body = {
        package_id: packageId,
        order_date: orderDate,
        email: email,
        iccid : iccid,
        paid : true,
        country_name : countryName,
        data_size : dataSize,
        period : period,
      }
      await axios.post(`${baseUrl}/api/auth/add_new_order`, req_body);
      const res = await axios.post(
          `${baseUrl}/api/esim/get_imsi_affect_package`,
          {
              packageTemplateId: packageId,
              validateDate: period,
              email: email,
              iccid : iccid
          }
      );
      if(!iccid){
        const affect_body = {
            email : email,
            package_id : packageId,
            account_id : res.data.affectPackageToSubscriber.accountId,
            iccid : res.data.affectPackageToSubscriber.iccid,
            order : res.data.affectPackageToSubscriber.userSimName,
            paid : true,
            title : countryName,
            country_name : countryName
        };
        await axios.post(`${baseUrl}/api/auth/newOrder`, affect_body);
      }

      const request_body = {
          package_id: packageId,
          order_date: orderDate,
          email: email,
      }
      await axios.post(`${baseUrl}/api/auth/remove_order`, request_body);
      window.location.href = `${window.location.origin}/mysims`;
      dispatch(eSimActions.deleteOrder());
    } catch (e) {
      setMessage(e.message);
      dispatch(eSimActions.deleteOrder());
      console.log(e);
    }
  };

  return (
    <div className="w-full sm:w-[80%] mx-auto sm:mb-[70px]">
      <div className="w-full h-auto px-10 pt-2">
        <Form onSubmit={handleSubmit} className="w-full">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              Email <font color="red">*</font>
            </Form.Label>
            <Form.Control
              type="email"
              value={email[0]}
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              Name <font color="red">*</font>
            </Form.Label>
            <Form.Control
              type="input"
              placeholder="Your Full Name"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
            <Form.Label>
              Billing Details <font color="red">*</font>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Country"
              className="mt-1"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              required
            />
            <Form.Control
              type="address"
              placeholder="Address"
              className="mt-1"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
            <InputGroup className="mt-1 mb-3">
              <Form.Control
                aria-label="City"
                placeholder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
              />
              <Form.Control
                aria-label="Post Code"
                placeholder="Post Code"
                value={postalCode}
                onChange={handlePostalCodeChange}
                required
                style={!isValid ? { color: "red" } : {}}
              />
            </InputGroup>
          </Form.Group>
          <div className="pay-part">
            <InputGroup className="flex mb-3 flex-nowrap">
              <Button
                size="lg"
                variant="outline-dark items-center"
                style={{ display: "flex" }}
                className="w-full text-gray-700"
              >
                <img src="/images/card.png" className="w-[20%]  sm:w-[100px]" alt="" />
                <span>CARD</span>
              </Button>
              <Button
                size="lg"
                variant="outline-dark items-center"
                style={{ display: "flex" }}
                className="w-full"
              >
                <img src="/images/applepay.png" className="w-[20%] sm:w-[100px]" alt="" />
                <span>APPLE PAY</span>
              </Button>
            </InputGroup>
            <Form.Label>
              Payment Information <font color="red">*</font>
            </Form.Label>
            <div className='card-element-outlined'>
              {stripePromise && (
                <CardElement options={cardStyle} />
              )}
            </div>
            
            <Form.Label>
              Status:&nbsp;
            </Form.Label>
            <Form.Label>
              {message}
            </Form.Label>
          </div>
          <div className="px-5 py-3" style={{ backgroundColor: "#00274C" }}>
            <div className="flex items-center justify-between">
              <div className="flex flex-col items-start justify-between text-white ">
                <h4>{dataSize} GB</h4>
                <h6>{minute} MINUTES</h6>
              </div>
              <div className="flex flex-col items-start justify-between text-white">
                <p>TOTAL</p>
                <h4>€ {cost} EUR</h4>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex items-center justify-between text-white ">
                <span className="mr-5">eSIM</span>
                <div className="w-[40px] h-[40px] rounded-full overflow-hidden border-2 border-white shadow-[0px_3px_5px_1px_rgba(0,0,0,0.3)]">
                  {countryCode ? (
                    <div className="w-full h-full">
                      {Flags[countryCode]({ title: countryName })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="flex flex-col items-start justify-between ml-3 text-white">
                <h6>{countryName}</h6>
                <span>VALID FOR {period} DAYS</span>
              </div>
            </div>
            <Button
              type="submit"
              variant="outline-light"
              className="w-full mb-[50px]"
              disabled={!stripe || !elements}
            >
              Pay
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Checkout;
