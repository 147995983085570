// import { useState } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Flags from "country-flag-icons/react/1x1";
import { useEffect, useState } from "react";
import { getCountryCode } from "../util/countryUtil";

const PackageItem = (props) => {

  const stringSlice = (str) => {
    return str.slice(0, 3) + "..." + str.slice(-3);
  }

  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    const r_code = getCountryCode(props.data.display_name);
    setCountryCode(r_code);
  }, [props.data.display_name]);
  return (
    <div className="flex mx-auto sm:mx-20 sm:rounded-full items-center justify-between w-full sm:w-[80%] sm:m-auto sm:mb-3 mb-2 bg-white border-2 border-black rounded-md">
      <div className="items-center w-[60px] sm:w-[100px] sm:h-[100px] h-[60px] m-auto rounded-full overflow-hidden border-3 border-white shadow-[0px_3px_8px_1px_rgba(0,0,0,0.3)]">
        {countryCode && (
          <div className="w-full h-full">
            {Flags[countryCode]({title:props.data.display_name})}
          </div>
        )}
      </div>
      <div className="text-center p-2 py-4 border-l-2 border-r-2 border-black h-full w-[20%] flex flex-col justify-center">
        <p className="m-0 text-lg font-bold md:text-3xl sm:text-sm">{props.data.giga_data} GB</p>
        <h6>{props.data.perioddays} DAYS</h6>
        {/* <p className="m-0 text-lg md:text-3xl sm:text-sm">{props.data.display_name.length > 10 ? stringSlice(props.data.display_name) : props.data.display_name}</p> */}
      </div>
      <div className="p-3 flex flex-col items-center sm:w-[45%] w-[50%]">

        <p className="text-[20px] w-full overflow-hidden font-bold text-center overflow-x-visible">
          {props.data.display_name}
          {/* {props.data.display_name.length > 11 ? props.data.display_name.slice(0, 10) + " " + props.data.display_name.slice(10) : props.data.display_name} */}
        </p>

        <Button 
          className="border-black w-[90%]"
          style={{ backgroundColor: "#00274C" }}
        >
          <Link
            to={`/plan/${props.data.location_id}`}
            className="text-white no-underline"
            style={{ backgroundColor: "#00274C" }}
          >
            FROM €{props.data.cost}
          </Link>
        </Button>
      </div>
    </div>
  );
};
export default PackageItem;
