import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useState } from "react";

const InsidePlanItem = ({ activeTime, endTime, status, voice, storage , usedStorage, usedVoice}) => (
  <Container>
    <Row>
      <Col>
        <h6>Your Plan Detail</h6>
      </Col>
      <Col>
        <b>
          <font color={status ? "lightgreen" : "grey"}>
            {status ? "ACTIVE" : "INACTIVE"}
          </font>
        </b>
      </Col>
    </Row>
    <Row>
      <Col>
        <p>Active : {activeTime}</p>
      </Col>
      <Col>
        <p>End : {endTime}</p>
      </Col>
    </Row>
    <Row>
      <Col>
        <h6>
          Data <font color="blue">{storage / Math.pow(1024, 3)}GB</font>
        </h6>
      </Col>
      <Col>
        <h6>
          Voice <font color="blue">{voice/60} MINUTES</font>
        </h6>
      </Col>
    </Row>
    <Row>
      <Row>
        <Col>
          <h6 className={Math.round(usedStorage/storage)/100 < 90 ? "text-primary" : "text-warning" }>DATA {Math.round(usedStorage / Math.pow(1024, 2))} MB USED</h6>
        </Col>
        <Col>
          <h6 className={(voice === 0 ? 0 : Math.round(usedVoice/voice)/100) < 90 ? "text-primary" : "text-warning"}>VOICE {usedVoice/60} MINUTES USED</h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <ProgressBar animated now={Math.round(usedStorage*100/storage)} />
        </Col>
        <Col>
          <ProgressBar animated now={voice === 0 ? 0 : Math.round(usedVoice * 100/voice) } />
        </Col>
      </Row>
    </Row>
  </Container>
);

export default InsidePlanItem;