import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import axios from "axios";
const UserManage = () => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const [users, setUsers] = useState([]);
    const [loading , setLoading] = useState(false);
    const auth = useSelector((x) => x.auth.value);
    const email = useState(auth?.email);
    useEffect(() => {
        if(email[0] !== "afliscano3@gmail.com"){
            alert("You have not access to this page");
            window.location.href=`${window.location.origin}`;
        }
        getUsers();
    }, []);

    const getUsers = async () => {
        setLoading(true);
        try{
            const res = await axios.post(`${baseUrl}/api/auth/get_users`);
            console.log(res);
            setUsers(res.data);
        }catch(e){
            console.log(e.message);
        }
        setLoading(false);
    }
    return (
        <div className="w-full sm:w-[80%] mx-auto">
            <h3 className="mt-10 mb-3 text-center">
                User List
            </h3>
            {loading ? (
                <img
                    src="/images/Spinner.png"
                    alt=""
                    className="relative top-[30%] w-[100px] animate-spin m-auto"
                />
            ) : (
                users.length > 0 && users.map((user, index) => {
                    return (
                        <div key={index} className="flex items-center h-24 justify-between shadow-[1px_2px_5px_grey] p-2 rounded-lg mb-3">
                            <div className="flex flex-col pl-10 items-start justify-between w-[80%]">
                                <h3>{user.username}</h3>
                                <p>{user.email}</p>
                            </div>
                            <div className="flex flex-col items-end justify-between pr-10">
                                <h1 className="text-green-500">{user.order.length}</h1>
                            </div>
                            <div className="flex flex-col items-end justify-between pr-10">
                                <p >{user.country}</p>
                                <p >{user.city}</p>
                            </div>
                        </div>
                    )
                })
            )}
        </div>
    )
}

export default UserManage;