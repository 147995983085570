const PlanItem = (props) => {
  const {
    perioddays,
    giga_data,
    cost,
    onClick,
    display_name,
    package_id
  } = props;

  const handleClick = () => {
    onClick(giga_data, cost, display_name, perioddays, package_id);
  }

  return (
    <div
      onClick={handleClick}
      className="flex items-center w-full m-auto mb-2 transition-all duration-300 border-black rounded-lg sm:px-10 sm:rounded-full hover:bg-gray-200 hover:shadow-md border-1 selection:bg-blue-400 hover:cursor-pointer"
    >
      <label className="px-2 flex w-full has-[:checked]:bg-blue-100 has-[:checked]:text-indigo-900 has-[:checked]:ring-indigo-200 rounded-lg hover:cursor-pointer">
        <input type="radio" name="payment_method" className="hidden checked:border-indigo-500" />
        <div className="flex flex-col w-[40%] border-r-[1px] border-gray-900">
          <p className="m-0">{display_name}</p>
          <h1 className="pr-20">
            {giga_data > 10 ? giga_data : ` ${giga_data}`}GB
          </h1>
        </div>
        <div className="flex flex-col w-[60%] items-end">
          <p className="m-0">Valid for {perioddays} Days</p>
          <h1 className="m-0">€ {cost}</h1>
        </div>
      </label>
    </div>
  );
};
export default PlanItem;