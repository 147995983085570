import Button from "react-bootstrap/Button";
import Flags from "country-flag-icons/react/1x1";
import { Link } from "react-router-dom";
import { getCountryCode } from "../util/countryUtil";
import { useEffect, useState } from "react";

const SimItem = (props) => {
  const [countryCode, setCountryCode] = useState("");
  useEffect(() => {
    setCountryCode(getCountryCode(props.country_name));
  }, []);
  return (
    <div className="flex items-center justify-between shadow-[1px_2px_5px_grey] p-2 rounded-lg mb-3">
      <div className="w-[20%] flex justify-center">
        <div className="w-[50px] h-[50px] rounded-full overflow-hidden border-2 border-white shadow-[0px_3px_5px_1px_rgba(0,0,0,0.3)]">
          {countryCode ? (
            <div className="w-full h-full">
              {Flags[countryCode]({ title: countryCode })}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="flex flex-col items-start justify-between w-[90%]">
        <h3 className="m-0">{props.title}</h3>
        <div className="flex items-center justify-between w-full">
          <div className="flex flex-col items-start justify-between">
            <p className="m-0">order#</p>
            <p className="m-0">{props.order}</p>
          </div>
          <div className="flex flex-col items-end justify-between">
            <p className="m-0">Purchased Date</p>
            <p className="m-0">{props.purchased_date}</p>
          </div>
        </div>
        <Link to={`/inside/${props.iccid}`} className="no-underlind">
          <Button
            size="sm"
            className="border-white"
            style={{ backgroundColor: "#00274C" }}
          >
            View Details & Top up
          </Button>
        </Link>
      </div>
    </div>
  );
};
export default SimItem;
