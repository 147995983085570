import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
import axios from "axios";
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [mailInput, setMailInput] = useState(true);
  const [code, setCode] = useState("");
  const [inputCode, setInputCode] = useState("");
  const [password, setPassword] = useState("");
  const baseUrl = process.env.REACT_APP_API_URL;
  const [errors, setErrors] = useState("");
  const nextSend = async () => {
    try{
        const code_res = await axios.post(`${baseUrl}/api/auth/send_code`, {email :email});
        setCode(code_res.data.data);
    }catch(e){
        setCode("");
    }
    setMailInput(false);
  }
  const verifyFunc = async () => {
    try{
        if(code === parseInt(inputCode)){
            await axios.post(`${baseUrl}/api/auth/updatePassword`, {email: email, password : password});
            setErrors("");
            window.location.href=`${window.location.origin}/login`;
        }else{
            setErrors("verify code is invalid");
            console.log("verify code is invalid");
        }
    }catch(e) {
        setErrors("Internal server Error");
        console.log('Internal server Error');
    }
  }

  return (
    <div className="w-full sm:w-[80%] m-auto h-full">
      <div className="w-full px-10 mt-24">
        <div className="mt-4 mb-4 text-center form-header">
          <h6>{mailInput ? "Enter your email for verification" : "We have sent verify code to your email. Enter the code you received and new password"}</h6>
        </div>
        {mailInput ? (
        <div>
           <Form.Control
             size="lg"
             type="email"
             value={email}
             onChange={(e) => setEmail(e.target.value)}
           />
           <Button
             className="w-full mt-3 bg-black hover:bg-gray-600"
             size="lg"
             id="btn_send"
             onClick={nextSend}
           >
             Next
           </Button>
         </div>
        ):(
          <div>
            <Form.Control
                size="lg"
                className={`mb-2`}
                value={inputCode}
                onChange={(e) => setInputCode(e.target.value)}
                placeholder="Verication Code"
                type="text"
            />
            {errors !== "" && <div className="text-left text-red-600">{errors}</div> }
            <div onClick={nextSend} className="w-full text-lg text-center text-blue-700 cursor-pointer">Resend</div>
            <Form.Control
                size="lg"
                className={`mb-2`}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />

            <Button
                className="w-full mt-3 bg-black"
                size="lg"
                type="submit"
                onClick={verifyFunc}
            >
                Verify & Update Password
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
