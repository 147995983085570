import CustomAccordion from "../components/CustomAccordion";
import Button from "react-bootstrap/Button";
import { useSelector } from 'react-redux';
import Flags from "country-flag-icons/react/1x1";
import { useDispatch } from "react-redux";
import { eSimActions } from "../store";
import { useEffect, useState } from "react";
import { getCountryCode } from "../util/countryUtil";
import axios from "axios";
import { Link } from "react-router-dom";
import { Col, Form, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import OrderItem from "../components/OrderItem";
const Account = () => {
  const auth = useSelector(x => x.auth.value);
  const email = auth?.email;
  const [user, setUser] = useState(null);
  const [quiz, setQuiz] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_API_URL;
  const [simArr , setSimArr] = useState([]);
  const [orders, setOrders] = useState([]);
  const logoutAction = () => {
    dispatch(eSimActions.deleteOrder());
    localStorage.removeItem("auth");
    window.location.href = `${window.location.origin}/`
  }

  useEffect(() => {
    getInfos();
  }, []);
  const activeNow = async (order_item) => {
      setLoading(true);
      try {
        const package_res = await axios.post(
          `${baseUrl}/api/esim/prepaid_package_template`,
          {
            listPrepaidPackageTemplate: {
              templateId: order_item.package_id,
            },
          }
        );
        const package_data = package_res.data.result[0];
        const res = await axios.post(
            `${baseUrl}/api/esim/get_imsi_affect_package`,
            {
                packageTemplateId: order_item.package_id,
                validateDate: package_data.perioddays,
                email: email,
                iccid : order_item.iccid
            }
        );
        
        const req_body = {
          package_id: order_item.pacakge_id,
          order_date: order_item.order_date,
          email: email,
        }
        await axios.post(`${baseUrl}/api/auth/remove_order`, req_body);
        
        if(!order_item.iccid){
            const affect_body = {
                email : email,
                package_id : order_item.package_id,
                account_id : res.data.affectPackageToSubscriber.accountId,
                iccid : res.data.affectPackageToSubscriber.iccid,
                order : res.data.affectPackageToSubscriber.userSimName,
                paid : true,
                title : package_data.display_name,
                country_name : package_data.display_name
            };
            await axios.post(`${baseUrl}/api/auth/newOrder`, affect_body);
        }
        dispatch(eSimActions.deleteOrder());
        getInfos();
    } catch (err) {
        console.log(err);
    }
    setLoading(false);
  }
  const getInfos = async () => {
    try{
      const res = await axios.post(`${baseUrl}/api/auth/get_sims`,{ email: email });
      const res_user = await axios.post(`${baseUrl}/api/auth/getAccount`, { email : email });
      setUser(res_user.data);
      setSimArr(res.data.data);
      const res_order = await axios.post(`${baseUrl}/api/auth/get_order_history`, {email : email});
      setOrders(res_order.data);
    }catch(err){
      
    }
  }
  const actionBtns = [
    {
      title: "My  eSims",
      content: (
        <Form.Group className="mb-3">
          {simArr.length > 0 && simArr.map((sim, index) => {
            return <div key={index} className="flex items-center justify-between shadow-[1px_2px_5px_grey] p-2 rounded-lg mb-3">
              <div className="w-[20%] flex justify-center">
                <div className="w-[50px] h-[50px] rounded-full overflow-hidden border-2 border-white shadow-[0px_3px_5px_1px_rgba(0,0,0,0.3)]">
                  <div className="w-full h-full">
                    {Flags[getCountryCode(sim.country_name)]({ title: getCountryCode(sim.country_name) })}
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start justify-between w-[90%]">
                <h3 className="m-0">{sim.title}</h3>
                <div className="flex items-center justify-between w-full">
                  <div className="flex flex-col items-start justify-between">
                    <p className="m-0">order#</p>
                    <p className="m-0">{sim.order}</p>
                  </div>
                  <div className="flex flex-col items-end justify-between">
                    <p className="m-0">Purchased Date</p>
                    <p className="m-0">{sim.purchased_date}</p>
                  </div>
                </div>
                <Link to={`/inside/${sim.iccid}`} className="no-underlind">
                  <Button
                    size="sm"
                    className="border-white"
                    style={{ backgroundColor: "#00274C" }}
                  >
                    View Details & Top up
                  </Button>
                </Link>
              </div>
            </div>
          })}
        </Form.Group>
      )
    },
    {
      title: "Billing",
      content: (         
        <Form.Group className="mb-3">
          <ListGroup className="cursor-pointer">
            <ListGroupItem className="justify-between" style={{display:"flex"}}><span>Country :</span> <span>{user && user.country}</span></ListGroupItem>
            <ListGroupItem className="justify-between" style={{display:"flex"}}><span>Address :</span> <span>{user && user.address}</span></ListGroupItem>
            <ListGroupItem className="justify-between" style={{display:"flex"}}><span>City    :</span> <span>{user && user.city}</span></ListGroupItem>
            <ListGroupItem className="justify-between" style={{display:"flex"}}><span>Postal Code :</span> <span>{user && user.postalCode}</span></ListGroupItem>
          </ListGroup>
        </Form.Group>
      ),
    },
    {
      title: "Order History",
      content: 
        <div className="w-full">
          {orders.length  > 0 ? (
            orders.map((item, index) => {
              if(item) return (
                <OrderItem activeNow={activeNow} key={index} data={item} />
              )
            })
          ):(
            <ListGroup className="cursor-pointer">
              <ListGroupItem>There is no history</ListGroupItem>
            </ListGroup>
          )
        }
       </div>
    },
    {
      title: "Rewards",
      content: "",
    },
    {
      title: "Help Support",
      content: "Here are some helpful document.",
    },
    {
      title: "FAQs",
      content: (
        <Form.Group>
            <Form.Label>
              Question :  <font color="red">*</font>
            </Form.Label>
            <Row>
              <div className="w-[70%">
                <Form.Control
                  type="text"
                  placeholder="Type the question"
                  value={quiz}
                  onChange={(e) => setQuiz(e.target.value)}
                  required
                />
              </div>
              {/* <Button className="primary">POST</Button> */}
            </Row>
        </Form.Group>
      ),
    },
    {
      title: "Currency",
      content : (
        <ListGroup className="cursor-pointer">
          <ListGroupItem>USD</ListGroupItem>
          <ListGroupItem>EUR</ListGroupItem>
          <ListGroupItem>CAD</ListGroupItem>
          <ListGroupItem>...</ListGroupItem>
        </ListGroup>
      )
    },
    {
      title: "Language",
      content: (
        <ListGroup className="cursor-pointer">
          <ListGroupItem>ENGLISH</ListGroupItem>
          <ListGroupItem>...</ListGroupItem>
        </ListGroup>
      ),
    },
  ];
  return (
    <div className="w-full px-4 bg-white sm:w-[80%] mx-auto">
      <div className="flex w-full bg-white mt-4 m-auto justify-between items-center shadow-[1px_2px_10px_-5px] p-2 mb-2 rounded-lg">
        <div className="flex items-center">
          <img
            className="w-20 cursor-pointer avatar rounded-circle"
            src="/images/avatar1.png"
            alt="Avatar"
          />
          <div className="flex flex-col ml-5">
            <h5 id="user">{auth?.username}</h5>
            <h6>{auth?.email}</h6>
          </div>
        </div>
        {/* <h5>EDIT</h5> */}
      </div>
      <div>
        <CustomAccordion data={actionBtns} />
      </div>
      <Button onClick={logoutAction}
        size="lg"
        className="w-full mb-[60px]"
        style={{ backgroundColor: "#00274C" }}
      >
        logout
      </Button>
    </div>
  );
};
export default Account;
