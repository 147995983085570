import Header from "./Header";
import Footer from "./Footer";
import { Alert } from "../components/Alert";

const Main = ({ children }) => {
  return (
    <div className="w-max-[1600px] flex flex-col justify-between mx-auto w-full">
      <Header></Header>
      <Alert />
      <main className="w-[100%]">{children}</main>
      <Footer></Footer>
    </div>
  );
};
export default Main;