import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Main from "./layouts/Main";
import Account from "./pages/Account";
import Checkout from "./pages/Checkout";
import Inside from "./pages/Inside";
import LandingPage from "./pages/Landing";
import LoginPage from "./pages/Login";
import MySims from "./pages/MySims";
import Plan from "./pages/Plan";
import RegisterPage from "./pages/Register";
import ForgotPassword from './pages/ForgotPassword';
import { PrivateRoute } from './components/PrivateRoute';
import { history } from './helpers/history';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Payment from './pages/Payment';
import UserManage from './pages/UserManage';
const stripePromise = loadStripe("pk_test_51PLrveC41duRrwiNGA7N11mu2ntgklp5V9YMhfuwEcYQd4ntF4FZcLJzitef9XaR42rC4gyjg0Xb94341qbBL2ZB003MSD82mo");
function App() {
  history.navigate = useNavigate();
  history.location = useLocation();
  return (
    <Main>
      <Elements stripe={stripePromise}>
        <Routes>
          <Route exact path='/' element={<LandingPage />} />
          <Route exact path='/login' element={<LoginPage />} />
          <Route exact path='/register' element={<RegisterPage />} />
          <Route exact path='/plan/:location_id' element={<Plan />} />
          <Route exact path ='/forgot' element={<ForgotPassword />} />
          <Route element={<PrivateRoute />}>
            <Route exact path='/account' element={<Account />} />
            <Route exact path='/checkout' element={<Checkout />} />
            <Route exact path="/usermanage" element={<UserManage />} />
            <Route exact path='/payment' element={<Payment />} />
            <Route exact path='/inside/:account_id' element={<Inside />} />
            <Route exact path='/mysims' element={<MySims />} />
          </Route>
        </Routes>
      </Elements>
    </Main>
  );
}

export default App;
