import React, { useEffect, useState } from "react";
import axios from "axios";
import Flags from "country-flag-icons/react/1x1";
import "swiper/css";
import "swiper/css/virtual";
import PackageItem from "../components/PackageItem";
const Landing = () => {
  const [slides, setSlides] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [typeAry, setTypeAry] = useState("local");
  const [keyword, setKeyword] = useState("");
  const baseUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    package_data();
    country_data();
  }, []);

  const package_data = async () => {
    try {
      const data = await axios.post(
        `${baseUrl}/api/esim/prepaid_package_template`,
        {
          listPrepaidPackageTemplate: {
            resellerId: "428",
          },
        }
      );
      setPackageList(data.data.result);
    } catch (error) {
      console.log("Error");
    }
  };
  const country_data = async () => {
    try {
      const response = await fetch("https://restcountries.com/v3.1/all");
      const data = await response.json();
      const countries = data.map((country) => ({
        name: country.name.common,
        flag: Flags[country.cca2]({ title: country.name.common }),
      }));

      return setSlides(countries);
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };

  
  return (
    <div className="w-[100%] bg-gray-200">

      <div className="p-1 text-center bg-white about">
        <h2 className="font-sans sm:text-[5em]">Ready to Roam?</h2>
        <p className="sm:text-[2em]">
          Explore our range of flexible plans and start your journey with ARKA
          ROAM today, Stay connected, stay global!
        </p>
      </div>

      <div className="w-[70%] mx-auto mt-3">
        <input type="search" placeholder="search" onChange={(e) => {setKeyword(e.target.value); }} className="form-control sm:text-[3em] sm:w-[60%] sm:h-16" />
      </div>

      <div className="text-center sm:w-[60%] mx-auto  my-3 w-[100%]">
      <button onClick={()=>setTypeAry("local")} className={`w-[30%] mx-1 px-6 sm:h-16 sm:text-[2em] py-2 ${typeAry === "local" ? "bg-[#00274C]" : "bg-gray-600"}  text-white font-semibold rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500`}>LOCAL</button>
      <button onClick={()=>setTypeAry("global")} className={`w-[30%] mx-1 px-6  sm:h-16 sm:text-[2em] py-2 ${typeAry === "global" ? "bg-[#00274C]" : "bg-gray-600"} text-white font-semibold rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500`}>GLOBAL</button>
      <button onClick={()=>setTypeAry("region")} className={`w-[30%] mx-1 px-6 sm:h-16 sm:text-[2em] py-2 ${typeAry === "region" ? "bg-[#00274C]" : "bg-gray-600"} text-white font-semibold rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500`}>REGIONAL</button>

      </div>
{/* 
      <Swiper modules={[Virtual]} spaceBetween={1}  slidesPerView={6} virtual>
        {slides.map((item, index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            <div >
              <div className="flex flex-col items-center w-[70px] h-[70px] m-auto rounded-full overflow-hidden border-5 border-white shadow-[0px_3px_10px_10px_rgba(0,0,0,1)">
                <div className="w-full h-full">{item.flag}</div>
              </div>
              <p className="text-center text-[10px]">{item && item.name}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper> */}

      <div className="px-3 items-start h-[600px]  overflow-y-auto flex flex-col relative scroll-bar">
        {packageList.length > 0 ? (
          <div className="relative top-0 w-full">
            {
              packageList.filter((packages) => packages.type === typeAry && packages.display_name.includes(keyword.toUpperCase())).map((item, index) => (
                <PackageItem key={index} data={item} slides={slides}/>
              ))
            }
          </div>
        ) : (
          <img
            src="/images/Spinner.png"
            alt=""
            className="mx-auto mt-24 w-[70px] sm:w-[150px] animate-spin"
          />
        )}
      </div>
      
      <div className="w-full px-5 rounded-lg">
        <h2 className="m-3 text-2xl text-[#00274C] font-bold text-center">Why Choose Us</h2>
        <div className="flex flex-col sm:flex-row">
            <div className="p-4 m-2 bg-[#00274C] text-white rounded">
                <h3 className="text-lg font-semibold">Stay Connected</h3>
                <p>Whether you're traveling for business or leisure, stay connected to what matters most. With ARKA Roam, you're always just a click away from friends and family.</p>
            </div>
            <div className="p-4 m-2 bg-[#00274C] text-white rounded">
                <h3 className="text-lg font-semibold">Save Money</h3>
                <p>Say goodbye to exorbitant roaming charges. With ARKA Roam's competitive pricing, you can enjoy global connectivity without breaking the bank.</p>
            </div>
            <div className="p-4 m-2 bg-[#00274C] text-white rounded">
                <h3 className="text-lg font-semibold">Peace Of Mind</h3>
                <p>Enjoy reliable and high-speed data wherever you roam. With leading network partners, ARKA Roam ensures you're always connected when it matters most.</p>
            </div>
        </div>
      </div>

      <div className="w-full px-5 rounded-lg mb-[70px]" >
        <h2 className="m-3 text-2xl text-[#00274C] font-bold text-center">Client Testimonials</h2>
        <div className="flex flex-col sm:flex-row">
            <div className="p-4 m-2 bg-white rounded">
                <p>"ARKA Roam made my recent trip to Europe a breeze! With their seamless activation process and reliable connectivity, I was able to stay in touch with family and friends back home without worrying about expensive roaming charges. Highly recommended for anyone who loves to travel"</p>
            </div>
            <div className="p-4 m-2 bg-white rounded">
                <p>"As a digital nomad, staying connected is crucial for my work. ARKA Roam has been a game-changer for me. Their flexible plans and extensive coverage have allowed me to work remotely from some of the most remote corners of the world without skipping a beat. Thank you, ARKA Roam, for keeping me connected wherever I go!"</p>
            </div>
            <div className="p-4 m-2 bg-white rounded">
                <p>"I've tried other roaming solutions in the past, but none compare to ARKA Roam. Their customer service is top-notch, and their commitment to affordability and reliability sets them apart. Whether I'm exploring a new city or lounging on a beach, I know ARKA Roam has me covered. I wouldn't travel without it!"</p>
            </div>
        </div>
      </div>

      {/* <div className="w-full text-center">
        <Button
          className="m-auto mt-2 mb-2 border-black"
          style={{ backgroundColor: "#00274C" }}
        >
          Load more +
        </Button>
      </div> */}
    </div>
  );
};

export default Landing;
