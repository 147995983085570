import { useEffect, useState } from "react";
import { getCountryCode } from "../util/countryUtil";
import Flags from "country-flag-icons/react/1x1";
import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URL;
const OrderItem = (props) => {
    const [countryName, setCountryName] = useState("");
    const [pckdatabyte, setPackdatabyte] = useState("");
    const [cost, setCost] = useState("");
    const [period, setPeriode] = useState("");
    useEffect(() => {
        console.log(props.data);
        getPackageData();    
    }, [props.data]);

    const getPackageData = async () => {
        try{
            const res = await axios.post(`${baseUrl}/api/esim/prepaid_package_template`, {
                listPrepaidPackageTemplate: {
                    templateId: props.data.package_id,
                },
            });
            console.log(res);
            setCountryName(res.data.result[0].display_name);
            setPackdatabyte(res.data.result[0].giga_data);
            setCost(res.data.result[0].cost);
            setPeriode();
        }catch(e){

        }
    }
    const getCode = (location_name) => {
        return getCountryCode(location_name);
    }
    
    return (
        <div className="flex items-center justify-center w-full mb-2 transition-all duration-300 border-black rounded-lg border-1 selection:bg-blue-400 ">
            <div className="w-[15%] h-[15%] m-auto rounded-full overflow-hidden border-5 border-white shadow-[0px_3px_8px_1px_rgba(0,0,0,0.3)]">
                <div className="flex items-center justify-center w-full h-full">
                    {Flags[getCode(countryName)]({ })}
                </div>
            </div>
            <div className="flex w-full px-2 rounded-lg">
                <div className="flex flex-col justify-center w-[30%]">
                    <p className="m-0">{countryName}</p>
                    <p className="pr-20 m-0 border-gray-900">
                        {pckdatabyte} GB
                    </p>
                </div>
                <div className="flex justify-between w-[70%]">
                    <div className="flex flex-col w-[24] justify-center">
                        {props.data.affected ? <button disabled={true} className="w-full h-12 bg-green-600 rounded-xl">AFFECTED</button> :
                                                <button className="w-full h-12 bg-green-600 rounded-xl" onClick={() => props.activeNow(props.data)}>AFFECT</button> }
                    </div>
                    <div className="flex flex-col items-start">
                        <p className="m-0">Valid {period} days</p>
                        <p className="m-0">€ {cost} </p>
                    </div>
                    <div className="flex flex-col items-start">
                        <p className="m-0">Order Date</p>
                        <p className="m-0">{props.data.order_date.split("T")[0]} </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderItem;