import { createSlice } from '@reduxjs/toolkit';


// create slice

const name = 'eSim';
const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers});

// exports

export const eSimActions = { ...slice.actions };
export const eSimReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    dataSize: localStorage.getItem('dataSize'),
    minute: localStorage.getItem('minute'),
    cost: localStorage.getItem('cost'),
    countryName: localStorage.getItem('countryName'),
    period: localStorage.getItem('period'),
    packageId: localStorage.getItem('packageId'),
    iccid : localStorage.getItem("iccid"),
  }
}

function createReducers() {
  return {
    setDataSize,
    setMinute,
    setCost,
    setCountryName,
    setPeriod,
    setPackageId,
    setIccid,
    deleteOrder
  };
  function deleteOrder() {
    localStorage.removeItem('dataSize');
    localStorage.removeItem('minute');
    localStorage.removeItem('cost');
    localStorage.removeItem('countryName');
    localStorage.removeItem('period');
    localStorage.removeItem('packageId');
    localStorage.removeItem('iccid');
  }
  function setDataSize(state, action) {
    state.dataSize = action.payload;
    localStorage.setItem('dataSize', action.payload);
  }
  function setMinute(state, action) {
    state.minute = action.payload;
    localStorage.setItem('minute', action.payload);
  }
  function setCost(state, action) {
    state.cost = action.payload;
    localStorage.setItem('cost', action.payload);
  }
  function setIccid(state, action){
    state.iccid = action.payload;
    localStorage.setItem("iccid",  action.payload);
  }
  function setCountryName(state, action) {
    state.countryName = action.payload;
    localStorage.setItem('countryName', action.payload);
  }
  function setPeriod(state, action) {
    state.period = action.payload;
    localStorage.setItem('period', action.payload);
  }
  function setPackageId(state, action) {
    state.packageId = action.payload;
    localStorage.setItem('packageId', action.payload);
  }

}