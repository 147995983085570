import { useEffect, useState } from "react";
import PlanItem from "../components/PlanItem";
import VoicePlanItem from "../components/VoicePlanItem";
import Button from "react-bootstrap/esm/Button";
import { Link, useParams } from "react-router-dom";
import Flags from "country-flag-icons/react/1x1";
import axios from "axios";
import { useDispatch , useSelector} from "react-redux";
import { eSimActions } from "../store";
import { getCountryCode } from "../util/countryUtil";
const Plan = () => {
  const dispatch = useDispatch();
  const { location_id } = useParams();
  const baseUrl = process.env.REACT_APP_API_URL;
  const iccid = useSelector((x) => x.eSim.iccid);
  const [template, setTemplate] = useState([]);
  const [totalCost, setTotalCost] = useState(0.0);
  const [subValue, setSubValue] = useState(0.0);
  const [minute, setMinute] = useState("0");
  const [dataSize, setDataSize] = useState("0");
  const [countryName, setCountryName] = useState("");
  const [period, setPeriod] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [packageId, setPackId] = useState("");

  useEffect(() => {
    getLocationById();
  }, []);
  
  const getESim = (dataSize, minute, cost, countryName, period, package_id) => {
    dispatch(eSimActions.setCountryName(countryName));
    dispatch(eSimActions.setPeriod(period));
    dispatch(eSimActions.setDataSize(dataSize));
    dispatch(eSimActions.setMinute(minute));
    dispatch(eSimActions.setCost(cost));
    dispatch(eSimActions.setPackageId(package_id));
  };

  const getLocationById = async () => {
    try {
      const data = await axios.post(
        `${baseUrl}/api/esim/prepaid_package_template`,
        {
          listPrepaidPackageTemplate: {
            locationzoneid: location_id,
          },
        }
      );
      const res = data.data.result;
      res.sort((a, b) => a.cost - b.cost);
      if(res.length > 0)setCountryCode(getCountryCode(res[0].display_name));
      setTemplate(res);
    } catch (error) {
      console.error(error.message);
    }
  };

  const getInformation = (data, cost, name, periods, package_id) => {
    const value = parseFloat(cost);
    setDataSize(data);
    setTotalCost(value);
    setCountryName(name);
    setPeriod(periods);
    setPackId(package_id);
  };

  const getVoiceData = (minute, subValue) => {
    const value = parseFloat(subValue);
    setMinute(minute);
    setSubValue(value);
  };
  const voiceArr = [
    {
      title: "MINUTES",
      subTitle: "",
      minute: "0",
      subValue: "0.00",
    },
    {
      title: "MINUTES",
      subTitle: "",
      minute: "100",
      subValue: "10.00",
    },
    {
      title: "MINUTES",
      subTitle: "",
      minute: "200",
      subValue: "17.00",
    },
  ];

  return (
    <div className="w-full sm:w-[80%] mx-auto h-screen px-3 overflow-y-auto">
      {template.length > 0 ? (
        <>
          <div className="flex p-2 pb-10">
            <div className="w-[15%] h-[15%] sm:w-[100px] sm:h-[100px] rounded-full overflow-hidden border-5 border-white shadow-[0px_3px_8px_1px_rgba(0,0,0,0.3)]">
              {countryCode ? (
                <div className="w-full h-full">
                  {Flags[countryCode]({ title: countryName })}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="flex flex-col items-start justify-between py-2 ml-5">
              <p className="m-0">{template[0].display_name}</p>
              <h3 className="m-0">{template[0].display_name}</h3>
              <p className="m-0">VALID FOR {template[0].perioddays} DAYS</p>
            </div>
          </div>
          <fieldset>
            {template.map((item, index) => (
              <PlanItem
                {...item}
                onClick={() =>
                  getInformation(
                    item.giga_data,
                    item.cost,
                    item.display_name,
                    item.perioddays,
                    item.package_id
                  )
                }
                key={index}
              />
            ))}
          </fieldset>
{/* 
          <div className="voice">
            <h4 className="text-center">ADD VOICE MINUTE</h4>
            {voiceArr.map((item, index) => (
              <VoicePlanItem
                {...item}
                onClick={() => {
                  getVoiceData(item.minute, item.subValue);
                }}
                key={index}
              />
            ))}
          </div> */}

          <div
            className="w-[90%] mx-auto sm:w-[78%] px-3 py-6 fixed bottom-[60px]"
            style={{ backgroundColor: "#00274C" }}
          >
            <div className="flex items-center justify-between w-full">
              <div className="flex flex-col w-[60%] items-start justify-between text-white ">
                <h4>{dataSize} GB</h4>
                <h6>{minute} MINUTES</h6>
              </div>
              <div className="flex flex-col w-[40%] items-start border-l border-white pl-2 justify-between text-white">
                <p>TOTAL</p>
                <h4>€{totalCost + subValue} EUR</h4>
              </div>
            </div>
            {totalCost + subValue > 0 ? (
              <Link to={`/checkout`}>
                <Button
                  variant="outline-light mt-3"
                  className="w-full"
                  onClick={getESim(dataSize, minute, totalCost + subValue, countryName, period, packageId)}
                >
                  Buy Now {iccid && <>(Charge)</>}
                </Button>
              </Link>
            ) : (
              <Link to={`/payment`}>
                <Button
                  variant="outline-light mt-3"
                  className="w-full"
                  onClick={getESim(dataSize, minute, totalCost + subValue, countryName, period, packageId)}
                >
                  Buy Now {iccid && <>(Charge)</>}
                </Button>
              </Link>
            )}
          </div>
        </>
      ) : (
        <div className="justify-center w-full">
          <img
            src="/images/Spinner.png"
            alt=""
            className="mx-auto mt-[200px] w-[70px] sm:w-[100px] animate-spin"
          />
        </div>
      )}
    </div>
  );
};
export default Plan;
