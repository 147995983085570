import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { history } from "../helpers/history";
import { alertActions } from "../store";
import { userActions } from "../store";

import { useSelector } from "react-redux";
import { getCountryCode } from "../util/countryUtil";
import Flags from "country-flag-icons/react/1x1";

const Register = () => {
  // const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  // const [error, setError] = useState(false);

  const dispatch = useDispatch();
  
  const dataSize = useSelector((x) => x.eSim.dataSize);
  const minute = useSelector((x) => x.eSim.minute);
  const cost = useSelector((x) => x.eSim.cost);
  const countryName = useSelector((x) => x.eSim.countryName);
  const period = useSelector((x) => x.eSim.period);
  const [countryCode , setCountryCode] = useState(null);
  
  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, formState, handleSubmit } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  useEffect(() => {
    if(countryName && countryName !== "")
    {
      const code = getCountryCode(countryName);
      setCountryCode(code);
    }
  }, []);
  async function onSubmit(data) {
    dispatch(alertActions.clear());
    try {
      await dispatch(userActions.register(data)).unwrap();

      // redirect to login page and display success alert
      history.navigate("/login");
      dispatch(
        alertActions.success({
          message: "Registration successful",
          showAfterRedirect: true,
        })
      );
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  }

  return (
    <div className="w-full">
      <div className="w-full px-10">
        <div className="mt-4 mb-4 text-center form-header">
          <h3>Register</h3>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Control
            size="lg"
            className={`mb-2 ${errors.firstName ? "is-invalid" : ""}`}
            id="username"
            type="text"
            {...register("username")}
            placeholder="Toni Aala"
          />
          <Form.Control
            size="lg"
            className={`mb-2 ${errors.firstName ? "is-invalid" : ""}`}
            type="email"
            id="email"
            {...register("email")}
            placeholder="example@gmail.com"
          />
          <div className="invalid-feedback">{errors.email?.message}</div>
          <Form.Control
            size="lg"
            id="password"
            type={showPassword ? "text" : "password"}
            {...register("password")}
            className={`${errors.firstName ? "is-invalid" : ""}`}
            placeholder="Enter Password"
          />
          <div className="invalid-feedback">{errors.password?.message}</div>
          <div className="flex items-center justify-center">
            <input
              id="check"
              size="lg"
              type="checkbox"
              onClick={() => {
                setShowPassword((showPassword) => !showPassword);
              }}
            />
            <label htmlFor="check" className="ml-1 cursor-pointer">
              Password Show
            </label>
          </div>

          <Button
            className="w-full mt-3 bg-black"
            size="lg"
            type="submit"
            id="btn_submit"
            disabled={isSubmitting}
          >
            {isSubmitting && (
              <span className="spinner-border spinner-border-sm me-1"></span>
            )}
            Sign up
          </Button>
        </form>
        <div className="flex items-center my-2">
          <div className="h-[1px] flex-1 bg-gray-300"></div>
          <span className="m-3 text-gray-400">or continue with</span>
          <div className="h-[1px] flex-1 bg-gray-300"></div>
        </div>
        <Button
          variant="outline-light"
          className="w-full text-black"
          style={{ backgroundColor: "#dddcdc" }}
          size="lg"
        >
          <div className="flex items-center justify-center">
            <img src="/images/google.png" alt="" />
            <p className="mb-0">Google</p>
          </div>
        </Button>
        <p className="mt-3 text-center text-gray-600">
          By clicking continue, you agree to our <b>Terms of service</b> <br />
          and <b>Privacy Policy</b>
        </p>
      </div>

      {countryCode &&  (
        <div className="px-5 py-3" style={{ backgroundColor: "#00274C" }}>
          <div className="flex items-center justify-between">
            <div className="flex flex-col items-start justify-between text-white ">
              <h4>{dataSize} GB</h4>
              <h6>{minute} MINUTES</h6>
            </div>
            <div className="flex flex-col items-start justify-between text-white">
              <p>TOTAL</p>
              <h4>€ {cost} EUR</h4>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex items-center justify-between text-white ">
              <span className="mr-5">eSIM</span>
              <div className="w-[40px] h-[40px] rounded-full overflow-hidden border-2 border-white shadow-[0px_3px_5px_1px_rgba(0,0,0,0.3)]">
                {countryCode ? (
                  <div className="w-full h-full">
                    {Flags[countryCode]({ title: countryName })}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="flex flex-col items-start justify-between ml-3 text-white">
              <h6>{countryName}</h6>
              <span>VALID FOR {period} DAYS</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Register;
