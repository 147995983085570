import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { history } from '../helpers/history';

const Header = () => {
  const goBack = () => {
    let { from } = history.location.state || { from: { pathname: '/' } };
    history.navigate(from);
  }
  return (
    <header className="sticky top-0 z-20 w-full">
        <Row className="items-center header align-center sm:h-[9vh] py-2 px-10">
          <Col xs={4} onClick={goBack} className="cursor-pointer header-bar">
            <img src="/images/back.png" className="w-4 sm:w-[20px] ml-5 rotate-180" alt="" />
          </Col>
          <Col xs={4} className="header-logo">
            <img src="/images/logo.png" className="sm:h-[6vh] m-auto" alt="" />
          </Col>
          <Col xs={4} className="hidden header-action sm:right-10">
            <Button  className="float-right">
              LOGIN
            </Button>
          </Col>
          <Col xs={4} className="text-right text-white header-currency sm:text-[25px]">
            (€) EUR
          </Col>
        </Row>
    </header>
  );
};

export default Header;
