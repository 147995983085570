import { getCountryCode } from "../util/countryUtil";
import Flags from "country-flag-icons/react/1x1";
const OtherPlan = (props) => {
    const getCode = (location_name) => {
        return getCountryCode(getName(location_name));
    }
    const getName = (location_name) => {
        let countryName = location_name.replace(/\s*\d+GB\s*/, '');
        countryName = countryName.replace("ARK_", "");
        countryName = countryName.replace(/^((?:[^_]+_?)+?)_.*/, '$1');
        countryName = countryName.split("_")[0];
        return countryName;
    }
    const handleClick = (subpackId) => {
        // eslint-disable-next-line no-restricted-globals
        if(confirm("Do you want to use this plan?")){
            
        }
    }
    return (
        <div className="w-full">
            {props.data.length > 0 ? ( props.data.map((plan, index) => {
                return <div key={index}
                    onClick={() => handleClick(plan.subscriberprepaidpackageid)}
                    className="flex items-center justify-center w-full mb-2 transition-all duration-300 border-black rounded-lg border-1 selection:bg-blue-400 "
                >
                    <div className="w-[15%] h-[15%] m-auto rounded-full overflow-hidden border-5 border-white shadow-[0px_3px_8px_1px_rgba(0,0,0,0.3)]">
                        <div className="flex items-center justify-center w-full h-full">
                            {Flags[getCode(plan.rdbLocationZones.locationzonename)]({ })}
                        </div>
                    </div>
                    <div className="flex w-full px-2 rounded-lg">
                        <div className="flex flex-col justify-center w-[30%]">
                            <p className="m-0">{getName(plan.rdbLocationZones.locationzonename)}</p>
                            <p className="pr-20 m-0 border-gray-900">
                                {Math.round(plan.pckdatabyte/ Math.pow(1024, 3))} GB
                            </p>
                        </div>
                        <div className="flex justify-between w-[70%]">
                            <div className="flex flex-col w-[40%] justify-center">
                                <button className="w-full h-12 bg-green-600 rounded-xl">{plan.active ? "ACTIVE" : "INACITVE"}</button>
                            </div>
                            <div className="flex flex-col items-start">
                                <p className="m-0">ACTIVE : {plan.tsactivationutc.split("T")[0]} </p>
                                <p className="m-0">EXPIRE : {plan.tsexpirationutc.split("T")[0]}</p>
                            </div>
                        </div>
                    </div>
                </div>
            })) : (
                <h3>There is no other plan</h3>
            )}

        </div>
    );
}

export default OtherPlan;

/*
plan.subscriberprepaidpackageid,
    plan.priority,
    plan.pckprops.databyte,
    plan.pckmocsecond,
    plan.perioddays,
    plan.active,
    plan.rdbLocationZones.locationzonename
*/